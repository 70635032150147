<template>
    <div class="systemAnnouncement">
        <Top-Nav></Top-Nav>
        系统公告
    </div>
</template>
<script>
import TopNav from '../../components/common/TopNav.vue';
export default {
  name: 'SystemAnnouncement',
  components:{TopNav},
  data() {
    return {
      
    }
  },
  methods:{
    
  }
}
</script>